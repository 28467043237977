import React from 'react'
import {Switch, Route} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Dashboard from './pages/dashboard'
import UserProfile from './pages/user-profile'
import Login from './pages/login'
import AddUser from './pages/add-user'
import AddCat from './pages/add-cat'
import AddSubCat from './pages/add-subcat'
import AddUserAdmin from './pages/add-admin'
import Clients from './pages/clients'
import AddZone from './pages/add-zone'
import AddTavoli from './pages/add-tavoli'





const Routes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/user-profile/:id"
        component={UserProfile}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-user"
        component={AddUser}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-cat"
        component={AddCat}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-subcat"
        component={AddSubCat}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-admin"
        component={AddUserAdmin}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/clients"
        component={Clients}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-zone"
        component={AddZone}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/add-tavoli"
        component={AddTavoli}
      ></PrivateRoute>
      <PrivateRoute exact path="/" component={Dashboard}></PrivateRoute>
      <Route exact path="/login" component={Login}></Route>
    </Switch>
  );
}
export default Routes
