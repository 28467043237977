import React from 'react'
import logo from './images/Logoj.png'

const Index = ({title, subtitle, children}) => {
  return (
    <div
      className="flex flex-col bg-white border border-grey-200 p-8 w-full max-w-xl"
      style={{
        borderRadius: "2rem",
        webkitBoxShadow: "0px 0px 47px 0px rgba(107,107,107,1)",
        mozBoxShadow: "0px 0px 47px 0px rgba(107,107,107,1)",
        boxShadow: "0px 0px 47px 0px rgba(107,107,107,1)",
      }}
    >
      <div className="flex flex-col w-full mb-4">
        <center>
          <img src={logo} width="40%" alt="astro tribus"/>
        </center>
      </div>
      {children}
    </div>
  );
}

export default Index
