import {combineReducers} from 'redux'
import allColors from './all-colors'
import dashboard from './dashboard'
import colors from './colors'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import user from './user'

const rootReducer = combineReducers({
  allColors,
  dashboard,
  colors,
  config,
  leftSidebar,
  palettes,
  user
})

export default rootReducer
