
import React, { useState, useEffect } from 'react'
import SectionTitle from '../components/section-title'
import { FaTrash, FaCheckCircle } from 'react-icons/fa'
import Widget from '../components/social-feed/widget'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Tab, Tabs, Form, Button, Spinner, Container, Row, Col, } from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar'
import "react-colorful/dist/index.css";
import MUIDataTable from 'mui-datatables';

import ReactTooltip from 'react-tooltip';


const { parseCookies } = require('nookies');



const UserProfile = (props) => {

  //GET DATA FROM BACKEND - SETTING STATES
  const [key, setKey] = useState('details');
  const [allUsers, viewUsers] = useState({})
  const [allApps, viewApps] = useState([{}])
  const [allAppsUn, viewAppsUn] = useState([{}])
  const [allInvoice, viewInvoice] = useState([{}])
  const [allPreventivi, viewPreventivi] = useState([{}])


  const history = useHistory()

  const [allUnInvoice, viewUnInvoice] = useState({})
  const [allPaInvoice, viewPaInvoice] = useState({})

  const [allSettings, viewSettings] = useState('')


  const [loading, setLoading] = useState(true)
  const [avatar, setAvatar] = useState('')
  const [deleted, setDeleted] = useState(false)
  const { register, handleSubmit, errors } = useForm();
  const cookies = parseCookies()
  const [showErr, setErr] = useState('')
  const [showSucc, setSucc] = useState('')
  const [showErr1, setErr1] = useState('')
  const [showSucc1, setSucc1] = useState('')



  //Recupero i dati dal db per paramentri Clienti
  useEffect(() => {
    let mounted = true
    const id = props.match.params.id
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/products/fetchProdbyId/${id}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data[0])
          let name = users.data[0].fname
          setAvatar(name.charAt(0))

        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()



  //Gestisco il passaggio tra le tab
  const changeTab = (k) => {
    setKey(k)
  }

  //Elimino l'utente
  const deleteUser = () => {
    const cookies = parseCookies();
    const id = props.match.params.id
    const confirm = window.confirm('Questa azione cancellerà il prodotto, sei sicuro di voler procedere?')
    if(confirm === true) {
      //Canello Cliente
      axios.delete(`${window.$apiEndpoint}/products/delete-prod/${id}`, {
        headers: {
          'x-access-token': cookies.token
        }

      }

      ).then(res => {
        setSucc('Prodotto cancellato con successo, a breve la pagina verrà ricaricata.')
        setTimeout(() => {setDeleted(true)},2000)

      }).catch(err => {
        setErr('Erore - ' + err.message)
        console.log(err)
      })
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //Chiamata post per modifca dati utente
  const onSubmit = data => {
        const id_prodotto = data.id_prodotto;
        const nome_prodotto = capitalizeFirstLetter(data.nome_prodotto);
        const costo = data.costo;
        const categoria_padre = data.categoria_padre.toUpperCase().trim();
        const categoria_figlio = data.categoria_figlio.toUpperCase().trim();

    //Controllo se il codice cliente è selezionato
    if (!id_prodotto) {
      return setErr1('Id non selezionato')
    }

    const confirmation = window.confirm('Sei sicuro di voler modificare il prodotto selezionato?')
    if(confirmation === true) {

    axios.post(`${window.$apiEndpoint}/products/update`, {
      id_prodotto: id_prodotto,
      nome_prodotto: nome_prodotto,
      costo: costo,
      categoria_padre: categoria_padre,
      categoria_figlio: categoria_figlio


    }, {
        headers: {
            'x-access-token' : cookies.token
        }
    }

    )
        .then(res => {
              setSucc1('Prodotto modifcato con successo, a breve la pagina verrà ricaricata.')
              setTimeout(() => {setDeleted(true)},2000)




        }).catch(err => {
          setErr1('Erore - ' + err.message)
        })
      }
}



  if(deleted) {
    return <Redirect to={`/clients`} />;
  }

  return (

  <>

  {loading ? <>

    <SectionTitle title="DETTAGLI" subtitle="Prodotto" />

    <ReactTooltip />


    <Container fluid>
                        <Row>
                        <Col className="card border-left-warning  shadow h-100 py-6 px-4" xs={12}>
                        <div className="ml-auto flex-shrink-0 space-x-2 hidden lg:flex">
                              <p data-tip="Elimina prodotto">
                              <Button
                              size="sm"
                              type="submit"
                              variant="danger"
                              style={{
                              borderRadius: '2rem',
                              paddingLeft: '1.2rem',
                              paddingRight: '1.2rem',
                              paddingTop: '0.7rem',
                              paddingBottom: '0.7rem',
                              }}
                              onClick={deleteUser}
                              >
                              <FaTrash></FaTrash>
                              </Button>
                              </p>

                            </div>

                      <div>
                      <p className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                      <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>
                        <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => changeTab(k)}>

                                    <Tab eventKey="details" title={<span style={{ color: '#424242'}}>Dati</span>}>
                                      <div className="py-12 w-full">

                                      <Form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Row>

                                   <Form.Control type="hidden" name="id_prodotto" ref={register({ required: true })} defaultValue={ allUsers.id } />


                                    <Form.Group  controlId="nome_prodotto" as={Col} >
                                    <Form.Label>Nome Prodotto</Form.Label>
                                    <Form.Control type="text" name="nome_prodotto" ref={register({ required: true })} defaultValue={ allUsers.nome_prodotto } />
                                    {errors.nome_prodotto && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>

                                    <Form.Group  controlId="costo" as={Col} >
                                    <Form.Label>Prezzo</Form.Label>
                                    <Form.Control type="text" name="costo" ref={register({ required: true })} defaultValue={ allUsers.costo_ivato } />
                                    {errors.costo && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                    </Form.Group>


                                </Form.Row>

                                <Form.Row>


                                <Form.Group  controlId="categoria_padre" as={Col} >
                                <Form.Label>Categoria</Form.Label>
                                <Form.Control type="text" name="categoria_padre" ref={register({ required: true })} defaultValue={ allUsers.categoria_padre } disabled ="true" />
                                {errors.categoria_padre && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                </Form.Group>

                                <Form.Group  controlId="categoria_figlio" as={Col} >
                                <Form.Label>Sotto Categoria</Form.Label>
                                <Form.Control type="text" name="categoria_figlio" ref={register({ required: true })} defaultValue={ allUsers.categoria_figlio }  disabled ="true" />
                                {errors.categoria_figlio && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                </Form.Group>

                                </Form.Row>



                                <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'red' }}>{showErr1}</p>
                                <p style={{ marginTop: '1vh', fontWeight: 'bold', color: 'green' }}>{showSucc1}</p>


                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "rgb(103 103 102)", borderColor: "rgb(103 103 102)", fontWeight: '600'}}>
                                    Modifica Prodotto
                                </Button>

                                    </Form>

                                      </div>




                                        </Tab>


                                    </Tabs>


                      </div>




                          </Col>

                          </Row>



                        </Container>
      </> : <center><Spinner animation="border" /></center>}
  </>
)
}

export default UserProfile;
