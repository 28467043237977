import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";


const AddTavoli = () => {

    const editor = useRef(null)


    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [allTables, viewTables] = useState([{}])

    const [allSCat, viewSCat] = useState([{}])

    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);

    const [selectedOption1, setSelectedOption1] = useState(null);
    const [selectedOptionLabel1, setSelectedOptionLabel1] = useState(null);




	  const [content, setContent] = useState('')

	  const config = {
		readonly: false,
    height: 'auto',
    placeholder: '',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	  }


    //Configuro le colonne della tabella
    const columns = ["Tavolo", "Zona"];
    const options = {
      filter: false,
      selectableRows: false,
      filterType: "dropdown",
      rowsPerPage: 5,
      textLabels: {
        body: {
          noMatch: "Spiacenti, nessun record corrispondente trovato",
          toolTip: "Ordinare",
          columnHeaderTooltip: column => `Ordinare per ${column.label}`
        },
        pagination: {
            next: "Pagina successiva",
            previous: "Pagina Precedente",
            rowsPerPage: "Righe per pagina:",
            displayRows: "of",
          },
          toolbar: {
            search: "Cerca",
            downloadCsv: "Scarica CSV",
            print: "Stampa",
            viewColumns: "Vedi Collonne",
            filterTable: "Tabella dei filtri",
          },
          filter: {
            all: "Tutto",
            title: "FILTRI",
            reset: "RIPRISTINA",
          },
          viewColumns: {
            title: "Mostra colonne",
            titleAria: "Mostra / Nascondi colonne tabella",
          },
    }
    };

    const theme = createMuiTheme({
      overrides: {
        MUIDataTableHeadCell:{
          root: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: '200',

          },
        },
        MuiIconButton: {
          root: {
            color: 'grey',

          },
        },
        MuiTableCell:{
          body: {
              backgroundColor: '#00968817',

            },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: '200',
            backgroundColor: ' white',
          },

        },

        MuiButton: {
          root: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: '200',
            backgroundColor: ' white',
          },

        },
        MuiTypography: {
          body2: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: '200',
            backgroundColor: ' white',
          },

        },
        MuiMenuItem: {
          root: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: '200',
            backgroundColor: ' white',
          },

        },
        MuiTableCell: {
          root: {
            fontFamily: "'Raleway', sans-serif",
            fontWeight: '400',
        },
      },
      MUIDataTableToolbar: {
        hover: {
          color: '#616161',
      },
    },
      },
    });


    //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/products/fetchZoneS`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db
useEffect(() => {
let mounted = true
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/products/fetchSCatP`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {
    if(mounted){
      viewSCat(users.data)
    }
  } catch (e) {
    console.log(e)
  }
  setLoading(true)
}
fetch();
return () => {
  mounted = false
}
}, [cookies.token])

console.log()


//Recupero i dati dal db
useEffect(() => {
  let mounted = true
  const fetch = async () => {
    const users = await axios.get(`${window.$apiEndpoint}/products/fetchTables`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
      if(mounted){
        viewTables(users.data)
      }
    } catch (e) {
      console.log(e)
    }
    setLoading(true)
  }
  fetch();
  return () => {
    mounted = false
  }
}, [cookies.token])

console.log(allUsers)

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const zona = selectedOption;
        const numero_tavolo = capitalizeFirstLetter(data.numero_tavolo);

        //Controllo se la categoria è selezionata
        if (!zona) {
          return (setErr('Zona non selezionata'),
          setTimeout(() => {setErr('')},3000))
        }

        //Controllo se la sotto categoria è selezionata
        if (!numero_tavolo) {
          return (setErr('Numero tavolo non selezionata'),
          setTimeout(() => {setErr('')},3000))
        }

          axios.post(`${window.$apiEndpoint}/products/createNumeroTavolo`, {
            zona: zona,
            numero_tavolo: numero_tavolo

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
              .then(res => {
                  setUser(res.data)
                  console.log(res.data)
                  setTimeout(() => {setCreated(true)},2500)
                  setSucc('Tavolo creato con successo, a breve la pagina verrà ricaricata.')
              }).catch(err => {
                setErr('Tavolo già esistente - ' + err.message)
              })

    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        return window.location.reload();
        //return window.location.reload();
    }

    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel();
    };


    return (
        <>
            <SectionTitle title="Inserisci" subtitle="Crea nuovo tavolo" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                                    <Col >
                                    <Form.Group  controlId="cliente" >
                                    <Form.Label>Seleziona Zona:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption} onChange={handleChange} options={allUsers} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Zona Selezionata: <strong>{selectedOption}</strong> {selectedOptionLabel} </pre>

                                    </Form.Group>
                                    </Col>


                                </Form.Row>

                                <Form.Row>

                                <Col>
                                <Form.Group  controlId="numero_tavolo" >
                                <Form.Label>Numero Tavolo</Form.Label>
                                <Form.Control type="text" name="numero_tavolo" ref={register({ required: true })} placeholder="Inserisci Numero Tavolo" />
                                {errors.numero_tavolo && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                </Form.Group>
                                </Col>

                                </Form.Row>
                                {errors.startDate && <p className="my-2" style={{  fontWeight: 'bold', color: '#f15a24' }}>Tutti i campi sono obbligatori</p>}
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>

                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "rgb(103 103 102)", borderColor: "rgb(103 103 102)", fontWeight: '600'}}>
                                    Crea Tavolo
                                </Button>
                                    </Form>
                        </div>

                                </Col>

                            </Row>



                        </Container>

                        <Container fluid>
                          <Row>
                          <Col xs={12}>
                          <div className="card border-left-warning my-6 shadow h-100 py-2 px-4">

                          <span style={{ fontSize: "2vh", fontWeight: "400", marginTop: '2vh',}}>Lista Tavoli</span>
                          <span style={{ fontSize: "1.2vh", fontWeight: "200"}}> Divisi per zona </span>

                                  <div className="overflow-x-scroll lg:overflow-hidden">
                                    {loading ?
                                    <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                    title={""}
                                    data={allTables}
                                    columns={columns}
                                    options={options}
                                    /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}
                                  </div>
                                  </div>

                                  </Col>

                              </Row>

                        </Container>

        </>
    )
}
export default AddTavoli
