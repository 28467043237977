import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Nav, NavDropdown} from 'react-bootstrap'
import { parseCookies, destroyCookie } from 'nookies'
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus ,faFileInvoice, faCogs, faHamburger, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chinelab from './img/logo.png'
import ReactTooltip from 'react-tooltip';





const NavbarCampus = () => {

  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);


  useEffect(() => {
    const cookies = parseCookies()
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
    }
  }, [setLogout])

  const logout = () => {
    const confirmation = window.confirm('Sei sicuro di voler uscire?')
    if(confirmation === true) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
    }
  }


  if (isLoggedOut) {
    return <Redirect to={`/login`} />;
  }



  return (

    <>
    <ReactTooltip />

            <nav className="flex items-center justify-between flex-wrap p-6" style={{backgroundColor: 'rgb(103 103 102)'}}>
            <div className="flex items-center flex-shrink-0 text-white mr-6">

              <h3 style={{ fontWeight: '900' }}>Jackbar</h3>
              <sup style={{ fontSize: '0.9rem', fontWeight: '600', color: '#424242', marginLeft: '0.2em', marginBottom: '1vh',  }} > Comande</sup>

            </div>
            <div className="block lg:hidden">
              <button
              className="flex items-center px-3 py-2 border rounded text-white border-teal-400 hover:text-white hover:border-white"
              onClick={() => toggleExpansion(!isExpanded)}
              >
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
              </button>
            </div>
            <div className={`${isExpanded ? `block` : `hidden`} w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
              <div className="text-sm lg:flex-grow">

              <a href="/" style={{fontSize: '1rem'}} data-tip="Dashboard" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-2"><FontAwesomeIcon icon={faHome} /> Home</a>
              <NavDropdown style={{fontSize: '1rem', color: 'white'}} title={<span data-tip="Gestisci Prodotti & Categorie" className="block lg:inline-block lg:mt-0 text-white hover:text-white" ><FontAwesomeIcon icon={faHamburger} /> Prodotti</span>} className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white" >
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/clients">Vedi Prodotti</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-user">Crea Prodotto</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-cat">Crea Categoria</NavDropdown.Item>
              <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-subcat">Crea Sotto Categoria</NavDropdown.Item>


            </NavDropdown>

            <NavDropdown style={{fontSize: '1rem', color: 'white', marginLeft: '-12px'}} title={<span data-tip="Gestisci Zone e Tavoli" className="block lg:inline-block lg:mt-0 text-white hover:text-white mr-0" ><FontAwesomeIcon icon={faTable} /> Zone</span>} className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white" >
            <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-zone">Crea Zone</NavDropdown.Item>
            <NavDropdown.Item  style={{fontSize: '0.9rem'}} href="/add-tavoli">Crea Tavoli</NavDropdown.Item>


          </NavDropdown>





              </div>
              <div>
              <a href="/add-admin" style={{fontSize: '1rem'}} data-tip="Crea utente admin" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-3"><FontAwesomeIcon icon={faUserLock} />  Utenti</a>
              <a href="/add-admin"   style={{fontSize: '1rem'}} data-tip="Logout" onClick={() => logout()} className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-3"><FontAwesomeIcon icon={faSignOutAlt} /> Esci </a>
              </div>
            </div>
          </nav>
  </>
  )
}
export default NavbarCampus
