import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import Loader from '../../components/loader'
import Hotkeys from '../../components/hotkeys'
import Backdrop from '../../components/backdrop'
import {NotificationContainer} from 'react-notifications'
import {CSSTransition} from 'react-transition-group'
import Navbar from '../../components/navbar'

const Layout1 = ({children}) => {
  const {config, palettes} = useSelector(
    state => ({
      config: state.config,
      palettes: state.palettes
    }),
    shallowEqual
  )
  const {layout, collapsed} = {...config}
  let {background, navbar, logo, leftSidebar, rightSidebar} = {
    ...palettes
  }
  let key = `${[
    layout,
    collapsed,
    background,
    navbar,
    logo,
    leftSidebar,
    rightSidebar
  ].join('-')}`

  return (
    <div
      data-layout={layout}
      data-background={background}
      >
      <Navbar/>
      <Loader />
      <Backdrop />
      <NotificationContainer />
      <Hotkeys key={key} />
      <CSSTransition in={collapsed} timeout={300} classNames="collapse-layout">
        <div className="wrapper">

          <div className={`main w-full`}>
            <div className="min-h-screen w-full p-4">{children}</div>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}
export default Layout1
