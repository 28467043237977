import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Section from '../components/dashboard/section'
import {Spinner} from 'react-bootstrap'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import MUIDataTable from 'mui-datatables';
import axios from 'axios'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Bar, Line } from 'react-chartjs-2'
import { faSignOutAlt, faUserPlus, faHome, faUserLock, faCalendarPlus, faHamburger, faPuzzlePiece} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button , Container, Row, Col} from 'react-bootstrap';
const bodyScrollLock = require('body-scroll-lock');




const { parseCookies } = require('nookies');





const Index = () => {
  //Setto gli state
  const [allProduct, viewProduct] = useState([{}])
  const [loading, setLoading] = useState(false)
  const [allCategorie, viewCategorie] = useState('')
  const [allUsersC, viewUsersC] = useState('')
  const [allProd, viewProd] = useState('')
  const cookies = parseCookies()
  const history = useHistory()



  //Recupero i dati dal db
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/products/fetch`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewProduct(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per i utenti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const usersC = await axios.get(`${window.$apiEndpoint}/users/fetchCusers`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsersC(usersC.data[0].NumberUsers)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per i clienti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const costumers = await axios.get(`${window.$apiEndpoint}/products/fetchCat`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewCategorie(costumers.data[0].Categories)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  //Recupero i dati dal db per i clienti totali
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const costumers = await axios.get(`${window.$apiEndpoint}/products/fetchProd`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewProd(costumers.data[0].Products)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])





  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  disableBodyScroll(Index);
  console.log()

  //Configuro le colonne della tabella
  const columns = ["Nome Prodotto", "Categoria Padre", "Sotto-Categoria", "Costo", "Id"];
  const options = {
    filter: false,
    selectableRows: false,
    filterType: "dropdown",
    rowsPerPage: 5,
    onRowClick:( rowData, rowState)  => {
      console.log(rowData[4])
      history.push('/user-profile/'+rowData[4])
    },
    textLabels: {
      body: {
        noMatch: "Spiacenti, nessun record corrispondente trovato",
        toolTip: "Ordinare",
        columnHeaderTooltip: column => `Ordinare per ${column.label}`
      },
      pagination: {
          next: "Pagina successiva",
          previous: "Pagina Precedente",
          rowsPerPage: "Righe per pagina:",
          displayRows: "of",
        },
        toolbar: {
          search: "Cerca",
          downloadCsv: "Scarica CSV",
          print: "Stampa",
          viewColumns: "Vedi Collonne",
          filterTable: "Tabella dei filtri",
        },
        filter: {
          all: "Tutto",
          title: "FILTRI",
          reset: "RIPRISTINA",
        },
        viewColumns: {
          title: "Mostra colonne",
          titleAria: "Mostra / Nascondi colonne tabella",
        },
  }
  };

  const theme = createMuiTheme({
    overrides: {
      MUIDataTableHeadCell:{
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',

        },
      },
      MuiIconButton: {
        root: {
          color: 'grey',

        },
      },
      MuiTableCell:{
        body: {
            backgroundColor: '#00968817',

          },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },

      MuiButton: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiTypography: {
        body2: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiMenuItem: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '200',
          backgroundColor: ' white',
        },

      },
      MuiTableCell: {
        root: {
          fontFamily: "'Raleway', sans-serif",
          fontWeight: '400',
      },
    },
    MUIDataTableToolbar: {
      hover: {
        color: '#616161',
    },
  },
    },
  });

  const ErrorChart = ({ data }) => {
    return (
      <div>
        <Bar data={data}>
        </Bar>
      </div>
    );

  }




  return (
    <>

<Container fluid>
      <Row className='mt-3 mb-2'>
        <Col md={4} className="my-2">

          <div class="card border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div style={{color:'rgb(103 103 102)'}} class="text-xs font-weight-bold text-uppercase mb-1">
                                            Prodotti</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{loading ?
                                            <p>{allProd}</p> : <center><Spinner animation="border" /></center>}</div>
                                        </div>
                                        <div class="col-auto">
                                           <FontAwesomeIcon style={{ color: 'grey',}} className='fa-3x text-gray-300' icon={faHamburger} />
                                        </div>
                                    </div>
                                </div>
                            </div>
        </Col>
        <Col md={4} className="my-2">

        <div class="card border-left-success shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div style={{color:'rgb(103 103 102)'}} class="text-xs font-weight-bold  text-uppercase mb-1">
                                                Categorie</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{loading ?
                                            <p>{allCategorie}</p> : <center><Spinner animation="border" /></center>}</div>
                                        </div>
                                        <div class="col-auto">
                                        <FontAwesomeIcon style={{ color: 'grey',}} className='fa-3x text-gray-300' icon={faPuzzlePiece} />
                                        </div>
                                    </div>
                                </div>
                            </div>
        </Col>
        <Col md={4} className="my-2">
        <div className="card border-left-warning shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div  style={{color:'rgb(103 103 102)'}} class="text-xs font-weight-bold  text-uppercase mb-1">
                                                Utenti di Sistema</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{loading ?
                                            <p>{allUsersC}</p> : <center><Spinner animation="border" /></center>}</div>
                                        </div>
                                        <div class="col-auto">
                                        <FontAwesomeIcon style={{ color: 'grey',}} className='fa-3x text-gray-300' icon={faUserLock} />
                                        </div>
                                    </div>
                                </div>
                            </div>
        </Col>
      </Row>
    </Container>


    <Container fluid>
      <Row>
      <Col xs={12}>
      <div className="card border-left-warning mt-6 shadow h-100 py-2 px-4">

            <span style={{ fontSize: "2vh", fontWeight: "400", marginTop: '2vh',}}>Lista Prodotti</span>
            <span style={{ fontSize: "1.2vh", fontWeight: "200"}}> Cliccando sulla righa si entra nel dettaglio del prodotto</span>

              <div className="overflow-x-scroll lg:overflow-hidden">
                {loading ?
                <MuiThemeProvider theme={theme}>
                <MUIDataTable
                title={""}
                data={allProduct}
                columns={columns}
                options={options}
                /></MuiThemeProvider>: <center><Spinner animation="border" /></center>}
              </div>
              </div>

              </Col>

          </Row>

    </Container>
    </>
  )
}
export default Index
