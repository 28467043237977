import React, { useState } from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies';

const AddCat = () => {
    //Setto gli state
    const [isCreated, setCreated] = useState(false)
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const [showSucc, setSucc] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    //Chiamata post per inserimento cliente
    const onSubmit = data => {
        const nome_categoria = data.nome_categoria.toUpperCase().trim( );

        axios.post(`${window.$apiEndpoint}/products/createCat`, {

            nome_categoria: nome_categoria,

        }, {
            headers: {
                'x-access-token' : cookies.token
            }
        })
            .then(res => {
                setUser(res.data)
                //Setto un Timeout per la risposta
                setTimeout(() => {setCreated(true)},2500)
                setSucc('Categoria creata con successo.')
            }).catch(err => {
                setErr('La categoria risulta essere già esistente - ' + err.message)
            })
    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        //Sleep 1
        return window.location.reload();
    }


    return (
        <>
            <SectionTitle title="Inserisci" subtitle="Crea nuova categoria" />



                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Row>

                                        <Form.Group  controlId="nome_categoria" as={Col} >
                                        <Form.Label>Nome Categoria</Form.Label>
                                        <Form.Control type="text" name="nome_categoria" ref={register({ required: true })} placeholder="Inserisci Nome Categoria" />
                                        {errors.nome_categoria && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                        </Form.Group>

                                    </Form.Row>


                                    <p className="my-2"  style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                    <p className="my-2"  style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>


                                    <Button variant="info" size="md" type="submit" style={{ backgroundColor: "rgb(103 103 102)", borderColor: "rgb(103 103 102)", fontWeight: '600'}}>
                                    Crea Categoria
                                </Button>
                                    </Form>
                        </div>

                                </Col>

                            </Row>

                        </Container>
        </>
    )
}
export default AddCat
