import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select';
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Button , Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { parseCookies } from 'nookies'
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AsyncSelect from 'react-select/async';
import JoditEditor from "jodit-react";


const AddUser = () => {

    const editor = useRef(null)


    //Setto gli state
    const [allUsers, viewUsers] = useState([{}])
    const [allSCat, viewSCat] = useState([{}])

    const [loading, setLoading] = useState(false)
    const [isCreated, setCreated] = useState(false)
    const [showSucc, setSucc] = useState('')
    const [user, setUser] = useState(null)
    const [showErr, setErr] = useState('')
    const { register, handleSubmit, errors } = useForm();
    const cookies = parseCookies()

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(null);

    const [selectedOption1, setSelectedOption1] = useState(null);
    const [selectedOptionLabel1, setSelectedOptionLabel1] = useState(null);




	  const [content, setContent] = useState('')

	  const config = {
		readonly: false,
    height: 'auto',
    placeholder: '',
    language: 'it' // all options from https://xdsoft.net/jodit/doc/
	  }


      //Recupero i dati dal db
    useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const users = await axios.get(`${window.$apiEndpoint}/products/fetchCatP`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(users.data)
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(true)
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [cookies.token])

  console.log()

  //Recupero i dati dal db
useEffect(() => {
let mounted = true
const fetch = async () => {
  const users = await axios.get(`${window.$apiEndpoint}/products/fetchSCatP`, {
    headers: {
      "x-access-token": cookies.token
    }
  });
  try {
    if(mounted){
      viewSCat(users.data)
    }
  } catch (e) {
    console.log(e)
  }
  setLoading(true)
}
fetch();
return () => {
  mounted = false
}
}, [cookies.token])

console.log()

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

    //Chiamata post per inserimento utente
    const onSubmit = data => {
        const categoria = selectedOption;
        const sotto_categoria = selectedOption1;
        const nome_prodotto = capitalizeFirstLetter(data.nome_prodotto);
        const costo = data.costo;

        //Controllo se la categoria è selezionata
        if (!categoria) {
          return (setErr('Categoria non selezionata'),
          setTimeout(() => {setErr('')},3000))
        }

        //Controllo se la sotto categoria è selezionata
        if (!sotto_categoria) {
          return (setErr('Sotto categoria non selezionata'),
          setTimeout(() => {setErr('')},3000))
        }

          axios.post(`${window.$apiEndpoint}/products/createProd`, {
            categoria: categoria,
            sotto_categoria: sotto_categoria,
            nome_prodotto: nome_prodotto,
            costo: costo

          }, {
              headers: {
                  'x-access-token' : cookies.token
              }
          })
              .then(res => {
                  setUser(res.data)
                  console.log(res.data)
                  setTimeout(() => {setCreated(true)},2500)
                  setSucc('Prodotto creato con successo, a breve la pagina verrà ricaricata.')
              }).catch(err => {
                setErr('Prodotto già esistente - ' + err.message)
              })

    }

    //Se lo state isCreated é true eseguo il redirect alla pagina profilo utente
    if (isCreated) {
        // redirect to home if signed up
        return window.location.reload();
        //return window.location.reload();
    }

    //Quando Seleziono

   const handleChange = selectedOption => {
      setSelectedOption(selectedOption.value);
      setSelectedOptionLabel();
    };

    const handleChange1 = selectedOption1 => {
       setSelectedOption1(selectedOption1.value);
       setSelectedOptionLabel1();
     };

    return (
        <>
            <SectionTitle title="Inserisci" subtitle="Crea nuovo prodotto" />
                <Container fluid>
                        <Row>
                        <Col xs={12}>
                        <div className="card border-left-warning  shadow h-100 py-6 px-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                                    <Col xs={6}>
                                    <Form.Group  controlId="cliente" >
                                    <Form.Label>Seleziona Categoria:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption} onChange={handleChange} options={allUsers} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Categoria Selezionata: <strong>{selectedOption}</strong> {selectedOptionLabel} </pre>

                                    </Form.Group>
                                    </Col>
                                        <Col>

                                    <Form.Label>Seleziona Sotto Categoria:</Form.Label>

                                    {loading ?
                                    <Select value={selectedOption1} onChange={handleChange1} options={allSCat} />
                                    : <center><Spinner size="sm" animation="border" variant="info" /></center>
                                    }
                                    <pre className="mt-2">Sotto Categoria Selezionata: <strong>{selectedOption1}</strong> {selectedOptionLabel1} </pre>


                                    </Col>

                                </Form.Row>

                                <Form.Row>

                                <Col>
                                <Form.Group  controlId="nome_prodotto" >
                                <Form.Label>Nome Prodotto</Form.Label>
                                <Form.Control type="text" name="nome_prodotto" ref={register({ required: true })} placeholder="Inserisci Nome Prodotto" />
                                {errors.nome_prodotto && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                </Form.Group>
                                </Col>

                                <Col>
                                <Form.Group  controlId="costo">
                                <Form.Label>Costo</Form.Label>
                                <Form.Control type="text" name="costo" ref={register({ required: true })} placeholder="Inserisci il costo" />
                                {errors.costo && <p style={{ marginTop: '1vh', fontWeight: 'bold', color: '#f15a24' }}>Il campo è obbligatorio</p>}
                                <small>Inserire il carattere . come separatore decimale Es. 1.10</small>
                                </Form.Group>
                                </Col>



                                </Form.Row>
                                {errors.startDate && <p className="my-2" style={{  fontWeight: 'bold', color: '#f15a24' }}>Tutti i campi sono obbligatori</p>}
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'red' }}>{showErr}</p>
                                <p className="my-2" style={{ fontWeight: 'bold', color: 'green' }}>{showSucc}</p>

                                <Button variant="info" size="md" type="submit" style={{ backgroundColor: "rgb(103 103 102)", borderColor: "rgb(103 103 102)", fontWeight: '600'}}>
                                    Crea Prodotto
                                </Button>
                                    </Form>
                        </div>

                                </Col>

                            </Row>



                        </Container>

        </>
    )
}
export default AddUser
